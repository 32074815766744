<template>
  <div class="reading-history">
    <h2>My Reading History</h2>
    <div class="search-and-sort">
      <input v-model="searchQuery" placeholder="Search books..." @input="searchBooks" class="search-bar">
      <div class="sort-options">
        <label>Sort by:</label>
        <select v-model="sortOption" @change="sortBooks">
          <option value="dateRead">Date Read</option>
          <option value="rating">Rating</option>
          <option value="title">Title</option>
        </select>
        <button @click="toggleSortOrder" class="sort-order-btn">
          {{ sortAscending ? '▲' : '▼' }}
        </button>
      </div>
    </div>
    <ul v-if="sortedAndFilteredHistory.length" class="book-list">
      <li v-for="item in sortedAndFilteredHistory" :key="item.id" class="book-item">
        <img v-if="item.book?.coverImage" :src="item.book.coverImage" :alt="item.book.title" class="book-cover">
        <div class="book-info">
          <h3>{{ item.book?.title || 'Unknown Title' }}</h3>
          <p>by {{ item.book?.author || 'Unknown Author' }}</p>
          <p>Read on: {{ formatDate(item.dateRead) }}</p>
          <div class="rating">
            <input
              type="number"
              v-model="item.rating"
              min="1"
              max="100"
              @change="updateRating(item)"
            >
            <span>/100</span>
          </div>
          <button @click="deleteBook(item)" class="delete-btn">Delete</button>
        </div>
      </li>
    </ul>
    <p v-else>No books found in your reading history.</p>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { generateClient } from 'aws-amplify/api';
import { listReadingHistories, getBook } from '../graphql/queries';
import { updateReadingHistory, deleteReadingHistory } from '../graphql/mutations';

const client = generateClient();

export default {
  name: 'ReadingHistoryView',
  setup() {
    const readingHistory = ref([]);
    const searchQuery = ref('');
    const sortOption = ref('dateRead');
    const sortAscending = ref(false);

    const filteredReadingHistory = computed(() => {
      if (!searchQuery.value) return readingHistory.value;
      return readingHistory.value.filter(item =>
        item.book?.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        item.book?.author.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const sortedAndFilteredHistory = computed(() => {
      let sorted = [...filteredReadingHistory.value];
      sorted.sort((a, b) => {
        let valueA, valueB;
        switch (sortOption.value) {
          case 'dateRead':
            valueA = new Date(a.dateRead);
            valueB = new Date(b.dateRead);
            break;
          case 'rating':
            valueA = a.rating || 0;
            valueB = b.rating || 0;
            break;
          case 'title':
            valueA = a.book?.title.toLowerCase() || '';
            valueB = b.book?.title.toLowerCase() || '';
            break;
        }
        if (valueA < valueB) return sortAscending.value ? -1 : 1;
        if (valueA > valueB) return sortAscending.value ? 1 : -1;
        return 0;
      });
      return sorted;
    });

    const fetchBookDetails = async (bookId) => {
      try {
        const result = await client.graphql({
          query: getBook,
          variables: { id: bookId }
        });
        return result.data.getBook;
      } catch (error) {
        console.error(`Error fetching book details for ID ${bookId}:`, error);
        return null;
      }
    };

    const fetchReadingHistory = async () => {
      try {
        const result = await client.graphql({
          query: listReadingHistories,
          variables: { limit: 1000 }
        });
        const items = result.data.listReadingHistories.items;
        const itemsWithBooks = await Promise.all(items.map(async (item) => {
          if (!item.book) {
            item.book = await fetchBookDetails(item.bookReadingHistoriesId);
          }
          return item;
        }));
        readingHistory.value = itemsWithBooks;
      } catch (error) {
        console.error('Error fetching reading history:', error);
      }
    };

    const updateRating = async (item) => {
      try {
        await client.graphql({
          query: updateReadingHistory,
          variables: {
            input: {
              id: item.id,
              rating: parseInt(item.rating)
            }
          }
        });
        console.log('Rating updated successfully');
      } catch (error) {
        console.error('Error updating rating:', error);
      }
    };

    const deleteBook = async (item) => {
      if (confirm(`Are you sure you want to delete "${item.book?.title}" from your reading history?`)) {
        try {
          await client.graphql({
            query: deleteReadingHistory,
            variables: {
              input: {
                id: item.id
              }
            }
          });
          readingHistory.value = readingHistory.value.filter(book => book.id !== item.id);
          console.log('Book deleted successfully');
        } catch (error) {
          console.error('Error deleting book:', error);
        }
      }
    };

    const searchBooks = () => {
      // This function is called on input, but filtering is handled by the computed property
    };

    const sortBooks = () => {
      // This function is called when sort option changes, but sorting is handled by the computed property
    };

    const toggleSortOrder = () => {
      sortAscending.value = !sortAscending.value;
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };

    onMounted(fetchReadingHistory);

    return {
      sortedAndFilteredHistory,
      searchQuery,
      sortOption,
      sortAscending,
      formatDate,
      updateRating,
      deleteBook,
      searchBooks,
      sortBooks,
      toggleSortOrder
    };
  }
};
</script>

<style scoped>
.reading-history {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.search-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.search-bar {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}
.sort-options {
  display: flex;
  align-items: center;
}
.sort-options label {
  margin-right: 10px;
}
.sort-options select {
  padding: 5px;
  font-size: 14px;
}
.sort-order-btn {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  cursor: pointer;
}
.book-list {
  list-style-type: none;
  padding: 0;
}
.book-item {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.book-cover {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
}
.book-info {
  flex-grow: 1;
}
.book-info h3 {
  margin-top: 0;
}
.rating {
  margin-top: 10px;
}
.rating input {
  width: 60px;
  margin-right: 5px;
}
.delete-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.delete-btn:hover {
  background-color: #cc0000;
}
</style>