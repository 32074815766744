// src/composables/useReadingHistory.js
import { ref, onMounted } from 'vue';
import { generateClient } from 'aws-amplify/api';
import { listReadingHistories, getBook } from '../graphql/queries';

const client = generateClient();

export function useReadingHistory() {
    const readingHistory = ref([]);

    const fetchBookDetails = async (bookId) => {
        try {
            const result = await client.graphql({
                query: getBook,
                variables: { id: bookId }
            });
            return result.data.getBook;
        } catch (error) {
            console.error(`Error fetching book details for ID ${bookId}:`, error);
            return null;
        }
    };

    const fetchReadingHistory = async () => {
        try {
            const result = await client.graphql({
                query: listReadingHistories,
                variables: { limit: 1000 }
            });
            const items = result.data.listReadingHistories.items;
            const itemsWithBooks = await Promise.all(items.map(async (item) => {
                if (!item.book) {
                    item.book = await fetchBookDetails(item.bookReadingHistoriesId);
                }
                return item;
            }));
            readingHistory.value = itemsWithBooks;
        } catch (error) {
            console.error('Error fetching reading history:', error);
        }
    };

    onMounted(fetchReadingHistory);

    return {
        readingHistory
    };
}