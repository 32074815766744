<template>
    <div class="feature-ideas">
      <h2>Feature Ideas</h2>
      <ul v-if="featureIdeas.length">
        <li v-for="idea in featureIdeas" :key="idea.id">
          <p>{{ idea.content }}</p>
          <small>Submitted on: {{ formatDate(idea.createdAt) }}</small>
        </li>
      </ul>
      <p v-else>No feature ideas submitted yet.</p>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { generateClient } from 'aws-amplify/api';
  import { listFeedbacks } from '../graphql/queries';
  
  const client = generateClient();
  
  export default {
    name: 'FeatureIdeas',
    setup() {
      const featureIdeas = ref([]);
  
      const fetchFeatureIdeas = async () => {
        try {
          const result = await client.graphql({
            query: listFeedbacks,
            variables: {
              filter: {
                type: {
                  eq: 'FEATURE_IDEA'
                }
              }
            }
          });
          featureIdeas.value = result.data.listFeedbacks.items;
        } catch (error) {
          console.error('Error fetching feature ideas:', error);
        }
      };
  
      const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
      };
  
      onMounted(fetchFeatureIdeas);
  
      return {
        featureIdeas,
        formatDate,
      };
    },
  };
  </script>
  
  <style scoped>
  .feature-ideas {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  small {
    color: #666;
  }
  </style>