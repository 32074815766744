<template>
    <div class="feedback-form">
      <h2>Submit Feedback</h2>
      <form @submit.prevent="submitFeedback">
        <div>
          <label for="feedbackType">Feedback Type:</label>
          <select v-model="feedbackType" id="feedbackType" required>
            <option value="GENERAL">General Feedback</option>
            <option value="FEATURE_IDEA">Feature Idea</option>
          </select>
        </div>
        <div>
          <label for="feedbackContent">Your Feedback:</label>
          <textarea v-model="feedbackContent" id="feedbackContent" required></textarea>
        </div>
        <button type="submit">Submit Feedback</button>
      </form>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { generateClient } from 'aws-amplify/api';
  import { createFeedback } from '../graphql/mutations';
  
  const client = generateClient();
  
  export default {
    name: 'FeedbackForm',
    setup() {
      const feedbackType = ref('GENERAL');
      const feedbackContent = ref('');
  
      const submitFeedback = async () => {
        try {
          await client.graphql({
            query: createFeedback,
            variables: {
              input: {
                type: feedbackType.value,
                content: feedbackContent.value,
                createdAt: new Date().toISOString(),
              },
            },
          });
          alert('Thank you for your feedback!');
          feedbackContent.value = '';
        } catch (error) {
          console.error('Error submitting feedback:', error);
          alert('Failed to submit feedback. Please try again.');
        }
      };
  
      return {
        feedbackType,
        feedbackContent,
        submitFeedback,
      };
    },
  };
  </script>
  
  <style scoped>
  .feedback-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-top: 10px;
  }
  
  select, textarea {
    margin-top: 5px;
    padding: 5px;
  }
  
  textarea {
    height: 100px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  </style>