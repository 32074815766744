/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchBooks = /* GraphQL */ `
  query SearchBooks($query: String!) {
    searchBooks(query: $query) {
      id
      title
      author
      isbn
      coverImage
      description
      readingHistories {
        nextToken
        __typename
      }
      wishlists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const getRecommendations = /* GraphQL */ `
  query GetRecommendations($books: [BookInput!]!) {
    getRecommendations(books: $books)
  }
`;
export const getReadingHistory = /* GraphQL */ `
  query GetReadingHistory($id: ID!) {
    getReadingHistory(id: $id) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateRead
      rating
      createdAt
      updatedAt
      bookReadingHistoriesId
      owner
      __typename
    }
  }
`;
export const listReadingHistories = /* GraphQL */ `
  query ListReadingHistories(
    $filter: ModelReadingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReadingHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateRead
        rating
        createdAt
        updatedAt
        bookReadingHistoriesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBook = /* GraphQL */ `
  query GetBook($id: ID!) {
    getBook(id: $id) {
      id
      title
      author
      isbn
      coverImage
      description
      readingHistories {
        nextToken
        __typename
      }
      wishlists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBooks = /* GraphQL */ `
  query ListBooks(
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWishlist = /* GraphQL */ `
  query GetWishlist($id: ID!) {
    getWishlist(id: $id) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateAdded
      createdAt
      updatedAt
      bookWishlistsId
      owner
      __typename
    }
  }
`;
export const listWishlists = /* GraphQL */ `
  query ListWishlists(
    $filter: ModelWishlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWishlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateAdded
        createdAt
        updatedAt
        bookWishlistsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      content
      type
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        type
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
