<template>
  <div class="book-search">
    <h2>Search Books</h2>
    <div class="search-container">
      <input v-model="searchQuery" @input="debouncedSearch" placeholder="Enter book title, author, or ISBN..." />
      <button @click="getAndUseRecommendation" :disabled="loading" class="recommendation-btn">
        {{ loading ? 'Loading...' : 'Use AI Recommendation' }}
      </button>
    </div>
    <div v-if="loading">Searching...</div>
    <div v-else-if="error">{{ error }}</div>
    <ul v-else-if="searchResults.length" class="book-list">
      <li v-for="book in searchResults" :key="book.id" class="book-item">
        <img :src="book.coverImage" :alt="book.title" class="book-cover">
        <div class="book-info">
          <h3>{{ book.title }}</h3>
          <p>by {{ book.author }}</p>
          <p v-if="book.isbn">ISBN: {{ book.isbn }}</p>
          <div class="book-description">
            <button @click="toggleDescription(book.id)">
              {{ book.showDescription ? 'Hide Description' : 'Show Description' }}
            </button>
            <p v-if="book.showDescription">{{ book.description }}</p>
          </div>
          <br>
          <button @click="addToWishlist(book)">Add to Wishlist</button>
          <button @click="markAsRead(book)">Mark as Read</button>
        </div>
      </li>
    </ul>
    <div v-else-if="searchQuery && !loading">No results found</div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from '@aws-amplify/auth';
import { searchBooks, getBook, listReadingHistories, listWishlists } from '../graphql/queries';
import { createBook, createWishlist, createReadingHistory, updateReadingHistory } from '../graphql/mutations';
import debounce from 'lodash.debounce';
import { useAIRecommendations } from '../composables/useAIRecommendations';

const client = generateClient({
  authMode: 'userPool'
});

export default {
  name: 'BookSearch',
  setup() {
    const searchQuery = ref('');
    const searchResults = ref([]);
    const loading = ref(false);
    const error = ref(null);
    const { recommendations, getRecommendations, hasReadingHistory } = useAIRecommendations();

    const checkAuth = async () => {
      try {
        await getCurrentUser();
        return true;
      } catch (e) {
        console.error('User is not authenticated', e);
        return false;
      }
    };

    const search = async () => {
      if (searchQuery.value.length < 3) return;

      loading.value = true;
      error.value = null;

      try {
        const result = await client.graphql({
          query: searchBooks,
          variables: { query: searchQuery.value }
        });
        searchResults.value = result.data.searchBooks.map(book => ({
          ...book,
          showDescription: false
        }));
      } catch (err) {
        console.error('Error searching books:', err);
        error.value = 'An error occurred while searching. Please try again.';
      } finally {
        loading.value = false;
      }
    };

    const debouncedSearch = debounce(() => search(), 300);

    watch(searchQuery, debouncedSearch);

    const getAndUseRecommendation = async () => {
      if (!hasReadingHistory.value) {
        alert("Please add books to your reading history to get recommendations.");
        return;
      }

      loading.value = true;
      error.value = null;

      try {
        await getRecommendations();
        if (recommendations.value && recommendations.value.length > 0) {
          searchQuery.value = recommendations.value[0];
          await search();
        } else {
          error.value = "No recommendations available. Try adding more books to your reading history.";
        }
      } catch (err) {
        console.error('Error getting recommendations:', err);
        error.value = 'An error occurred while getting recommendations. Please try again.';
      } finally {
        loading.value = false;
      }
    };

    const addToWishlist = async (book) => {
      if (!(await checkAuth())) {
        alert('Please sign in to add books to your wishlist.');
        return;
      }

      try {
        let existingBook = await getOrCreateBook(book);

        const wishlistResult = await client.graphql({
          query: listWishlists,
          variables: { filter: { bookWishlistsId: { eq: existingBook.id } } }
        });

        const existingWishlistItem = wishlistResult.data.listWishlists.items[0];

        if (existingWishlistItem) {
          alert('This book is already in your wishlist.');
          return;
        }

        const createWishlistInput = {
          bookWishlistsId: existingBook.id,
          dateAdded: new Date().toISOString()
        };

        const result = await client.graphql({
          query: createWishlist,
          variables: { input: createWishlistInput }
        });

        console.log('Wishlist created:', result.data.createWishlist);
        alert('Book added to wishlist!');
      } catch (err) {
        console.error('Error adding to wishlist:', err);
        alert('Failed to add book to wishlist. Please try again.');
      }
    };

    const markAsRead = async (book) => {
      if (!(await checkAuth())) {
        alert('Please sign in to mark books as read.');
        return;
      }

      try {
        let existingBook = await getOrCreateBook(book);

        const readingHistoryResult = await client.graphql({
          query: listReadingHistories,
          variables: { filter: { bookReadingHistoriesId: { eq: existingBook.id } } }
        });

        const existingReadingHistory = readingHistoryResult.data.listReadingHistories.items[0];

        if (existingReadingHistory) {
          const updateConfirm = confirm('This book is already in your reading history. Do you want to update its rating?');
          if (!updateConfirm) return;
        }

        const rating = prompt('Rate this book from 1 to 100:', existingReadingHistory ? existingReadingHistory.rating.toString() : '50');
        if (rating === null) return;

        const parsedRating = parseInt(rating);
        if (isNaN(parsedRating) || parsedRating < 1 || parsedRating > 100) {
          alert('Please enter a valid rating between 1 and 100.');
          return;
        }

        if (existingReadingHistory) {
          const updateReadingHistoryInput = {
            id: existingReadingHistory.id,
            dateRead: new Date().toISOString(),
            rating: parsedRating
          };

          await client.graphql({
            query: updateReadingHistory,
            variables: { input: updateReadingHistoryInput }
          });

          alert('Reading history updated!');
        } else {
          const createReadingHistoryInput = {
            bookReadingHistoriesId: existingBook.id,
            dateRead: new Date().toISOString(),
            rating: parsedRating
          };

          await client.graphql({
            query: createReadingHistory,
            variables: { input: createReadingHistoryInput }
          });

          alert('Book marked as read!');
        }
      } catch (err) {
        console.error('Error marking as read:', err);
        alert('Failed to mark book as read. Please try again.');
      }
    };

    const getOrCreateBook = async (book) => {
      let existingBook;
      try {
        const getBookResult = await client.graphql({
          query: getBook,
          variables: { id: book.id }
        });
        existingBook = getBookResult.data.getBook;
      } catch (error) {
        console.log('Book not found in our database, will create a new one');
      }

      if (!existingBook) {
        const createBookInput = {
          id: book.id,
          title: book.title,
          author: book.author,
          isbn: book.isbn,
          coverImage: book.coverImage,
          description: book.description
        };

        const createBookResult = await client.graphql({
          query: createBook,
          variables: { input: createBookInput }
        });

        existingBook = createBookResult.data.createBook;
      }

      return existingBook;
    };

    const toggleDescription = (bookId) => {
      const book = searchResults.value.find(b => b.id === bookId);
      if (book) {
        book.showDescription = !book.showDescription;
      }
    };

    return {
      searchQuery,
      searchResults,
      loading,
      error,
      debouncedSearch,
      addToWishlist,
      markAsRead,
      getAndUseRecommendation,
      toggleDescription
    };
  }
};
</script>

<style scoped>
.book-search {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.search-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
}

.recommendation-btn {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.recommendation-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.book-list {
  list-style-type: none;
  padding: 0;
}

.book-item {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.book-cover {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
}

.book-info {
  flex-grow: 1;
}

.book-info h3 {
  margin-top: 0;
}

.book-description {
  margin-top: 10px;
}

.book-description button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.book-description p {
  margin-top: 10px;
  font-style: italic;
}

button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>