<template>
  <div id="app">
    <div class="header">
      <img src="@/assets/bookwormer.png" alt="BookWormer.me Logo" class="logo">
      <h1>BookWormer.me</h1>
    </div>
    <authenticator v-slot="{ user, signOut }">
      <template v-if="user">
        <h2>Welcome, {{ getUserName(user) }}!</h2>
        <button @click="signOut">Sign Out</button>
        <nav>
          <button @click="currentView = 'search'">Search</button>
          <button @click="currentView = 'wishlist'">Wishlist</button>
          <button @click="currentView = 'history'">Reading History</button>
          <button @click="currentView = 'feedback'">Feedback</button>
          <button @click="currentView = 'featureIdeas'">Feature Ideas</button>
        </nav>
        <BookSearch v-if="currentView === 'search'" />
        <WishlistView v-if="currentView === 'wishlist'" />
        <ReadingHistoryView v-if="currentView === 'history'" />
        <FeedbackForm v-if="currentView === 'feedback'" />
        <FeatureIdeas v-if="currentView === 'featureIdeas'" />
        <AIRecommendations v-if="currentView === 'aiRecommendations'" />
      </template>
    </authenticator>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { Authenticator } from '@aws-amplify/ui-vue';
import { Amplify } from 'aws-amplify';
import confetti from 'canvas-confetti';
import BookSearch from './components/BookSearch.vue';
import WishlistView from './components/WishlistView.vue';
import ReadingHistoryView from './components/ReadingHistoryView.vue';
import FeedbackForm from './components/FeedbackForm.vue';
import FeatureIdeas from './components/FeatureIdeas.vue';
import AIRecommendations from './components/AIRecommendations.vue';

export default {
  name: 'App',
  components: {
    Authenticator,
    BookSearch,
    WishlistView,
    ReadingHistoryView,
    FeedbackForm,
    FeatureIdeas,
    AIRecommendations,
  },
  setup() {
    const getUserName = (user) => {
      if (user.signInDetails && user.signInDetails.loginId) {
        return user.signInDetails.loginId;
      }
      return user.username || 'User';
    };

    const currentView = ref('search');
    const isAuthenticated = ref(false);

    const triggerConfetti = () => {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    };

    onMounted(async () => {
      try {
        await Amplify.Auth.currentAuthenticatedUser();
        isAuthenticated.value = true;
      } catch (error) {
        isAuthenticated.value = false;
      }
    });

    watch(isAuthenticated, (newValue, oldValue) => {
      if (newValue && !oldValue) {
        triggerConfetti();
      }
    });

    return {
      getUserName,
      currentView,
      isAuthenticated,
    };
  },
};
</script>

<style>
@import '@aws-amplify/ui-vue/styles.css';

nav {
  margin: 20px 0;
}

button {
  margin: 0 10px;
  padding: 5px 10px;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px; /* Reduced top margin */
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Reduced bottom margin */
}

.logo {
  width: 100px; /* Adjusted to make the logo smaller. Modify as needed */
  height: auto;
  margin-right: 10px;
}
</style>