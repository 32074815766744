<template>
  <div class="wishlist">
    <h2>My Wishlist</h2>
    <div class="search-and-sort">
      <input v-model="searchQuery" placeholder="Search books..." @input="searchBooks" class="search-bar">
      <div class="sort-options">
        <label>Sort by:</label>
        <select v-model="sortOption" @change="sortBooks">
          <option value="title">Title</option>
          <option value="author">Author</option>
          <option value="isbn">ISBN</option>
        </select>
        <button @click="toggleSortOrder" class="sort-order-btn">
          {{ sortAscending ? '▲' : '▼' }}
        </button>
      </div>
    </div>
    <ul v-if="sortedAndFilteredWishlist.length" class="book-list">
      <li v-for="item in sortedAndFilteredWishlist" :key="item.id" class="book-item">
        <img :src="item.book?.coverImage" :alt="item.book?.title" class="book-cover">
        <div class="book-info">
          <h3>{{ item.book?.title }}</h3>
          <p>by {{ item.book?.author }}</p>
          <p v-if="item.book?.isbn">ISBN: {{ item.book?.isbn }}</p>
          
          <!-- Show/Hide Description Button -->
          <button @click="toggleDescription(item.id)" class="description-toggle-btn">
            {{ showDescriptions[item.id] ? 'Hide Description' : 'Show Description' }}
          </button>
          
          <!-- Description Text -->
          <p v-if="showDescriptions[item.id] && item.book?.description" class="book-description">
            {{ item.book?.description }}
          </p>
          <br>
          <button @click="removeFromWishlist(item.id)">Remove from Wishlist</button>
          <button @click="markAsRead(item)">Mark as Read</button>
        </div>
      </li>
    </ul>
    <p v-else>No books found in your wishlist.</p>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { generateClient } from 'aws-amplify/api';
import { listWishlists, getBook, listReadingHistories } from '../graphql/queries';
import { deleteWishlist, createReadingHistory, updateReadingHistory } from '../graphql/mutations';

const client = generateClient();

export default {
  name: 'WishlistView',
  setup() {
    const wishlist = ref([]);
    const searchQuery = ref('');
    const sortOption = ref('title');
    const sortAscending = ref(true);
    const showDescriptions = ref({}); // Object to track which descriptions are visible

    const filteredWishlist = computed(() => {
      if (!searchQuery.value) return wishlist.value;
      return wishlist.value.filter(item =>
        item.book?.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        item.book?.author.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        item.book?.isbn.includes(searchQuery.value)
      );
    });

    const sortedAndFilteredWishlist = computed(() => {
      let sorted = [...filteredWishlist.value];
      sorted.sort((a, b) => {
        let valueA, valueB;
        switch (sortOption.value) {
          case 'title':
            valueA = a.book?.title.toLowerCase() || '';
            valueB = b.book?.title.toLowerCase() || '';
            break;
          case 'author':
            valueA = a.book?.author.toLowerCase() || '';
            valueB = b.book?.author.toLowerCase() || '';
            break;
          case 'isbn':
            valueA = a.book?.isbn || '';
            valueB = b.book?.isbn || '';
            break;
        }
        if (valueA < valueB) return sortAscending.value ? -1 : 1;
        if (valueA > valueB) return sortAscending.value ? 1 : -1;
        return 0;
      });
      return sorted;
    });

    const fetchBookDetails = async (bookId) => {
      try {
        const bookData = await client.graphql({
          query: getBook,
          variables: { id: bookId }
        });
        return bookData.data.getBook;
      } catch (error) {
        console.error(`Error fetching book details for ID ${bookId}:`, error);
        return null;
      }
    };

    const fetchWishlist = async () => {
      try {
        const wishlistData = await client.graphql({
          query: listWishlists
        });
        const items = wishlistData.data.listWishlists.items;
        const itemsWithBooks = await Promise.all(items.map(async (item) => {
          const book = await fetchBookDetails(item.bookWishlistsId);
          return { ...item, book };
        }));
        wishlist.value = itemsWithBooks;
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    const fetchReadingHistories = async () => {
      try {
        const readingHistoriesData = await client.graphql({
          query: listReadingHistories
        });
        return readingHistoriesData.data.listReadingHistories.items;
      } catch (error) {
        console.error('Error fetching reading histories:', error);
        return [];
      }
    };

    const removeFromWishlist = async (id) => {
      try {
        await client.graphql({
          query: deleteWishlist,
          variables: { input: { id } }
        });
        wishlist.value = wishlist.value.filter(item => item.id !== id);
      } catch (error) {
        console.error('Error removing from wishlist:', error);
      }
    };

    const markAsRead = async (item) => {
      if (!item.book) {
        console.error('Cannot mark as read: Book details are missing');
        return;
      }
      try {
        const readingHistories = await fetchReadingHistories();
        const existingReadingHistory = readingHistories.find(r => r.bookReadingHistoriesId === item.book.id);

        if (existingReadingHistory) {
          const updateConfirm = confirm('This book is already in your reading history. Do you want to update its rating?');
          if (!updateConfirm) return;
        }

        const rating = prompt('Rate this book from 1 to 100:', existingReadingHistory ? existingReadingHistory.rating.toString() : '50');
        if (rating === null) return;

        const parsedRating = parseInt(rating);
        if (isNaN(parsedRating) || parsedRating < 1 || parsedRating > 100) {
          alert('Please enter a valid rating between 1 and 100.');
          return;
        }

        if (existingReadingHistory) {
          const updateReadingHistoryInput = {
            id: existingReadingHistory.id,
            dateRead: new Date().toISOString(),
            rating: parsedRating
          };

          await client.graphql({
            query: updateReadingHistory,
            variables: { input: updateReadingHistoryInput }
          });

          alert('Reading history updated!');
        } else {
          const createReadingHistoryInput = {
            bookReadingHistoriesId: item.book.id,
            dateRead: new Date().toISOString(),
            rating: parsedRating
          };

          await client.graphql({
            query: createReadingHistory,
            variables: { input: createReadingHistoryInput }
          });

          alert('Book marked as read!');
        }

        await removeFromWishlist(item.id);
      } catch (error) {
        console.error('Error marking as read:', error);
      }
    };

    const toggleDescription = (bookId) => {
      showDescriptions.value = {
        ...showDescriptions.value,
        [bookId]: !showDescriptions.value[bookId]
      };
    };

    const searchBooks = () => {
      // This function is called on input, but filtering is handled by the computed property
    };

    const sortBooks = () => {
      // This function is called when sort option changes, but sorting is handled by the computed property
    };

    const toggleSortOrder = () => {
      sortAscending.value = !sortAscending.value;
    };

    onMounted(fetchWishlist);

    return {
      sortedAndFilteredWishlist,
      searchQuery,
      sortOption,
      sortAscending,
      showDescriptions,
      removeFromWishlist,
      markAsRead,
      searchBooks,
      sortBooks,
      toggleSortOrder,
      toggleDescription
    };
  }
};
</script>

<style scoped>
.wishlist {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.search-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.search-bar {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}
.sort-options {
  display: flex;
  align-items: center;
}
.sort-options label {
  margin-right: 10px;
}
.sort-options select {
  padding: 5px;
  font-size: 14px;
}
.sort-order-btn {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  cursor: pointer;
}
.book-list {
  list-style-type: none;
  padding: 0;
}
.book-item {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.book-cover {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
}
.book-info {
  flex-grow: 1;
}
.book-info h3 {
  margin-top: 0;
}
.book-description {
  margin-top: 10px;
}
.description-toggle-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border: 1px solid #ddd;
  cursor: pointer;
}


.description-toggle-btn:hover {
  background-color: #45a049;
}
button {
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}
</style>
