import { ref, computed } from 'vue';
import { post } from 'aws-amplify/api';
import { useReadingHistory } from './useReadingHistory';

export function useAIRecommendations() {
  const { readingHistory } = useReadingHistory();
  const recommendations = ref([]);
  const loading = ref(false);
  const error = ref(null);

  const hasReadingHistory = computed(() => readingHistory.value.length > 0);

  const getRecommendations = async () => {
    if (!hasReadingHistory.value) {
      error.value = "No reading history available for recommendations.";
      return;
    }

    loading.value = true;
    error.value = null;

    try {
      const books = readingHistory.value.map(item => ({
        title: item.book?.title || 'Unknown Title',
        author: item.book?.author || 'Unknown Author',
        rating: item.rating || 0
      }));

      const responseWrapper = await post({
        apiName: 'restbookwormer',
        path: '/recommendations',
        options: {
          body: { books },
          timeout: 30000 // 30 seconds timeout
        }
      });

      const response = await responseWrapper.response;

      let jsonResult;
      if (response.body instanceof ReadableStream) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = '';

        let done = false;
        while (!done) {
          const chunk = await reader.read();
          done = chunk.done;
          if (chunk.value) {
            result += decoder.decode(chunk.value, { stream: !done });
          }
        }

        jsonResult = JSON.parse(result);
      } else if (typeof response.body === 'string') {
        jsonResult = JSON.parse(response.body);
      } else {
        jsonResult = response.body;
      }

      if (jsonResult) {
        recommendations.value = jsonResult;
      } else {
        throw new Error('No data received from the API');
      }
    } catch (err) {
      console.error('Error getting recommendations:', err);
      error.value = `Error: ${err.message}`;
      recommendations.value = [];
    } finally {
      loading.value = false;
    }
  };

  return {
    recommendations,
    loading,
    error,
    hasReadingHistory,
    getRecommendations
  };
}