/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReadingHistory = /* GraphQL */ `
  mutation CreateReadingHistory(
    $input: CreateReadingHistoryInput!
    $condition: ModelReadingHistoryConditionInput
  ) {
    createReadingHistory(input: $input, condition: $condition) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateRead
      rating
      createdAt
      updatedAt
      bookReadingHistoriesId
      owner
      __typename
    }
  }
`;
export const updateReadingHistory = /* GraphQL */ `
  mutation UpdateReadingHistory(
    $input: UpdateReadingHistoryInput!
    $condition: ModelReadingHistoryConditionInput
  ) {
    updateReadingHistory(input: $input, condition: $condition) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateRead
      rating
      createdAt
      updatedAt
      bookReadingHistoriesId
      owner
      __typename
    }
  }
`;
export const deleteReadingHistory = /* GraphQL */ `
  mutation DeleteReadingHistory(
    $input: DeleteReadingHistoryInput!
    $condition: ModelReadingHistoryConditionInput
  ) {
    deleteReadingHistory(input: $input, condition: $condition) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateRead
      rating
      createdAt
      updatedAt
      bookReadingHistoriesId
      owner
      __typename
    }
  }
`;
export const createWishlist = /* GraphQL */ `
  mutation CreateWishlist(
    $input: CreateWishlistInput!
    $condition: ModelWishlistConditionInput
  ) {
    createWishlist(input: $input, condition: $condition) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateAdded
      createdAt
      updatedAt
      bookWishlistsId
      owner
      __typename
    }
  }
`;
export const updateWishlist = /* GraphQL */ `
  mutation UpdateWishlist(
    $input: UpdateWishlistInput!
    $condition: ModelWishlistConditionInput
  ) {
    updateWishlist(input: $input, condition: $condition) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateAdded
      createdAt
      updatedAt
      bookWishlistsId
      owner
      __typename
    }
  }
`;
export const deleteWishlist = /* GraphQL */ `
  mutation DeleteWishlist(
    $input: DeleteWishlistInput!
    $condition: ModelWishlistConditionInput
  ) {
    deleteWishlist(input: $input, condition: $condition) {
      id
      book {
        id
        title
        author
        isbn
        coverImage
        description
        createdAt
        updatedAt
        owner
        __typename
      }
      dateAdded
      createdAt
      updatedAt
      bookWishlistsId
      owner
      __typename
    }
  }
`;
export const createBook = /* GraphQL */ `
  mutation CreateBook(
    $input: CreateBookInput!
    $condition: ModelBookConditionInput
  ) {
    createBook(input: $input, condition: $condition) {
      id
      title
      author
      isbn
      coverImage
      description
      readingHistories {
        nextToken
        __typename
      }
      wishlists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBook = /* GraphQL */ `
  mutation UpdateBook(
    $input: UpdateBookInput!
    $condition: ModelBookConditionInput
  ) {
    updateBook(input: $input, condition: $condition) {
      id
      title
      author
      isbn
      coverImage
      description
      readingHistories {
        nextToken
        __typename
      }
      wishlists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBook = /* GraphQL */ `
  mutation DeleteBook(
    $input: DeleteBookInput!
    $condition: ModelBookConditionInput
  ) {
    deleteBook(input: $input, condition: $condition) {
      id
      title
      author
      isbn
      coverImage
      description
      readingHistories {
        nextToken
        __typename
      }
      wishlists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      content
      type
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      content
      type
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      content
      type
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
