<template>
    <div class="ai-recommendations">
      <h2>AI Book Recommendations</h2>
      <button @click="getRecommendations" :disabled="loading || !hasReadingHistory">
        {{ loading ? 'Loading...' : 'Get Recommendations' }}
      </button>
      <div v-if="loading" class="loading-indicator">
        <p>{{ loadingMessage }}</p>
      </div>
      <ul v-if="recommendations && recommendations.length">
        <li v-for="(book, index) in recommendations" :key="index">
          {{ book }}
        </li>
      </ul>
      <p v-else-if="!loading && !error">No recommendations available.</p>
      <p v-if="error" class="error-message">{{ error }}</p>
      <p v-if="!hasReadingHistory" class="warning-message">Please add books to your reading history to get recommendations.</p>
    </div>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  import { useAIRecommendations } from '../composables/useAIRecommendations';
  
  export default {
    name: 'AIRecommendations',
    setup() {
      const { 
        recommendations, 
        loading, 
        error, 
        hasReadingHistory, 
        getRecommendations 
      } = useAIRecommendations();
  
      const loadingMessage = ref('Loading recommendations...');
  
      let loadingTimeoutId = null;
  
      watch(loading, (newValue) => {
        if (newValue) {
          loadingTimeoutId = setTimeout(() => {
            loadingMessage.value = "This is taking longer than expected. Please wait...";
          }, 10000);
        } else {
          if (loadingTimeoutId) {
            clearTimeout(loadingTimeoutId);
            loadingMessage.value = 'Loading recommendations...';
          }
        }
      });
  
      return {
        recommendations,
        loading,
        error,
        loadingMessage,
        hasReadingHistory,
        getRecommendations
      };
    }
  };
  </script>
  
  <style scoped>
  .ai-recommendations {
    margin-top: 20px;
  }
  button {
    margin-bottom: 10px;
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  .error-message {
    color: red;
    font-weight: bold;
  }
  .warning-message {
    color: orange;
    font-weight: bold;
  }
  .loading-indicator {
    margin-top: 10px;
    font-style: italic;
    color: #666;
  }
  </style>